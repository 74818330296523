@import "../styles/childrens.css";

:root {
  --card-height: 150px;
  --radius: var(--card-height);
  --schedule-width: 85%;
  --color-default: 150, 150, 150; /* gray */
  --color-surgeon: 0, 156, 222; /* blue */
  --color-app: 161, 90, 149; /* purple */
  --color-aud: 113, 204, 152; /* green */
  --color-slp: 254, 173, 119; /* orange */
  --color-dietician: 87, 41, 50; /* maroon */
  --color-pharmacist: 84, 142, 153; /* teal */
  --color-genetics-counselor: 191, 170, 174; /* dusty rose */
}

.provider-cards {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  max-width: 1200px;
}

.provider-card {
  position: relative;
  margin-bottom: 0.5rem;
}

.schedule {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: 5px;
  width: var(--schedule-width);
  height: var(--card-height);
  position: relative;
  overflow: hidden;
}

.schedule .avatar-paper {
  position: absolute;
  width: calc(2 * var(--radius) * 1.2);
  height: calc(2 * var(--radius));
  border-radius: 50%;
  left: calc(-1 * var(--radius));
  top: calc(50% - var(--radius));
  background-color: rgb(var(--color-default));
  box-shadow: rgba(var(--color-default), 0.4) 5px 5px, rgba(var(--color-default), 0.3) 10px 10px,
  rgba(var(--color-default), 0.2) 15px 15px, rgba(var(--color-default), 0.1) 20px 20px,
  rgba(var(--color-default), 0.05) 25px 25px;
}

.surgeon .schedule .avatar-paper,
.physician .schedule .avatar-paper {
  background-color: rgb(var(--color-surgeon));
  box-shadow: rgba(var(--color-surgeon), 0.4) 5px 5px, rgba(var(--color-surgeon), 0.3) 10px 10px,
    rgba(var(--color-surgeon), 0.2) 15px 15px, rgba(var(--color-surgeon), 0.1) 20px 20px,
    rgba(var(--color-surgeon), 0.05) 25px 25px;
}
.app .schedule .avatar-paper {
  background-color: rgb(var(--color-app));
  box-shadow: rgba(var(--color-app), 0.4) 5px 5px, rgba(var(--color-app), 0.3) 10px 10px,
    rgba(var(--color-app), 0.2) 15px 15px, rgba(var(--color-app), 0.1) 20px 20px, rgba(var(--color-app), 0.05) 25px 25px;
}
.audiologist .schedule .avatar-paper {
  background-color: rgb(var(--color-aud));
  box-shadow: rgba(var(--color-aud), 0.4) 5px 5px, rgba(var(--color-aud), 0.3) 10px 10px,
    rgba(var(--color-aud), 0.2) 15px 15px, rgba(var(--color-aud), 0.1) 20px 20px, rgba(var(--color-aud), 0.05) 25px 25px;
}
.slp .schedule .avatar-paper {
  background-color: rgb(var(--color-slp));
  box-shadow: rgba(var(--color-slp), 0.4) 5px 5px, rgba(var(--color-slp), 0.3) 10px 10px,
    rgba(var(--color-slp), 0.2) 15px 15px, rgba(var(--color-slp), 0.1) 20px 20px, rgba(var(--color-slp), 0.05) 25px 25px;
}
.dietician .schedule .avatar-paper {
  background-color: rgb(var(--color-dietician));
  box-shadow: rgba(var(--color-dietician), 0.4) 5px 5px, rgba(var(--color-dietician), 0.3) 10px 10px,
    rgba(var(--color-dietician), 0.2) 15px 15px, rgba(var(--color-dietician), 0.1) 20px 20px,
    rgba(var(--color-dietician), 0.05) 25px 25px;
}

.pharmacist .schedule .avatar-paper {
  background-color: rgb(var(--color-pharmacist));
  box-shadow: rgba(var(--color-pharmacist), 0.4) 5px 5px, rgba(var(--color-pharmacist), 0.3) 10px 10px,
    rgba(var(--color-pharmacist), 0.2) 15px 15px, rgba(var(--color-pharmacist), 0.1) 20px 20px,
    rgba(var(--color-pharmacist), 0.05) 25px 25px;
}

.genetics-counselor .schedule .avatar-paper {
  background-color: rgb(var(--color-genetics-counselor));
  box-shadow: rgba(var(--color-genetics-counselor), 0.4) 5px 5px, rgba(var(--color-genetics-counselor), 0.3) 10px 10px,
    rgba(var(--color-genetics-counselor), 0.2) 15px 15px, rgba(var(--color-genetics-counselor), 0.1) 20px 20px,
    rgba(var(--color-genetics-counselor), 0.05) 25px 25px;
}

.schedule .main-container {
  position: relative;
  display: flex;
  margin: 0.25rem;
}

.schedule .provider-cell {
  padding-top: 0.5rem;
  flex: 0 0 calc(var(--card-height) * 1.2);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.schedule .provider-cell .provider-avatar {
  margin-bottom: 0.5rem;
}

.schedule .provider-cell .provider-name,
.schedule .provider-cell .provider-nickname,
.schedule .provider-cell .provider-role {
  padding-left: 1rem;
  align-self: start;
  font-size: 0.9rem;
  color: white;
}
.schedule .provider-cell .provider-nickname {
  font-size: 1rem;
}

.schedule .provider-cell .provider-role {
  text-transform: uppercase;
  font-size: 0.8rem;
}

.schedule .info-cell {
  margin-left: 3rem;
  width: 100%;
  padding: 0 1rem;
}

.provider-notes {
  width: calc(var(--schedule-width) - var(--radius) + 3rem);
  left: 0;
  transform: translate(calc(2rem + var(--radius)), -2.75rem);
  border-radius: 10px;
  padding: 1rem;
  box-shadow: rgba(0, 156, 222, 0.24) 0px 3px 8px;
  box-shadow: rgba(0, 114, 162, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
