.dividerHitbox {
  cursor: col-resize;
  align-self: stretch;
  display: flex;
  align-items: center;
  padding: 0 1rem;
}

.divider {
  width: 2px;
  height: 100%;
  margin: 1rem;
  border: 2px solid #808080;
}

.rightPane {
  flex: 1;
}

.splitView {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
