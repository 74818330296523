@import "../styles/childrens.css";

:root {
}

.feedback-cards-container {
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
  gap: 1rem;
}

.page-accordion > .Mui-expanded {
  margin-right: 1rem !important;
}

.feedback-card {
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.feedback-grid {
  display: grid;
  grid-template-columns: 1fr 4fr;
  gap: 1rem;
  line-height: 1.3;
  padding: 1rem;
}

.feedback-grid:not(:first-child) {
  border-top: 1px solid var(--childrens-medium-blue);
  padding-top: 0.5rem;
}

.feedback-grid div {
  display: flex;
  flex-direction: column;
}

.feedback-grid button {
  align-self: end;
  margin-top: 1rem;
}

.ago {
  font-size: 0.9rem;
  margin-bottom: 0.2rem;
}

.submitted-by {
  font-size: 0.8rem;
  color: var(--childrens-field-dark-heading-color);
}
