/*
CHILDREN'S MINNESOTA BRANDING COLOR PALETTE

Primay Colors
#012169 - dark blue   (use white text if background)
#009CDE - medium blue (use white text if background)

Secondary Colors
#572932 - maroon      (use white text if background)
#F5DADF - pink        (use gray text if background)
#71CC98 - green       (use white text if background)
#B9D9EB - light blue  (use gray text if background)
#DF4661 - red         (use white text if background)
#FEDB00 - yellow      (use gray text if background)
#A15A95 - purple      (use white text if background)
#FEAD77 - orange      (use white text if background)

Text coolors
#000000 - Black
#5C5C5C - Dark gray
#ACAEB0 - Light blue gray

Schedule Colors
#B5A3A6 - Maroon for the U
#75A697 - Darker green for Woodbury (wby)
#e3c1b3 - Light red for Mpls Annex

FONT FAMILIES

Headlines, subheadings. Headlines in upper case.
font-family: "Trade Gothic", Arial, sans-serif;

Body copy, quotes
font-family: Georgia, serif;

Functional body copy like caption
font-family: Avenir, Arial, sans-serif;
*/

* {
  --childrens-dark-blue: rgb(1, 33, 105);
  --childrens-medium-blue: rgb(0, 156, 222);
  --childrens-light-blue: rgb(185, 217, 235);
  --childrens-hilight-blue: rgba(185, 217, 235, 0.5);
  --childrens-red: rgb(223, 70, 97);
  --childrens-green: #71cc98;
  --childrens-purple: rgb(161, 90, 149);
  --childrens-pink: #f5dadf;
  --childrens-yellow: #fedb00;
  --childrens-field-light-heading-color: #999;
  --childrens-field-dark-heading-color: #888;
  --childrens-field-darker-heading-color: #555;
  --childrens-heading-font-family: "trade-gothic-next-compressed";
  --light-blue-background: #f4f7ff; /* used for background for coverage map elements */
  --childrens-nav-selected: #01133d;
  --childrens-mpls: #c0a602;
  --childrens-stp: rgb(0, 156, 222);
  --childrens-wbry: #61ae83;
  --childrens-mtka: rgb(161, 90, 149);
  --childrens-mg: #dc8999;
}
