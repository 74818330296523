.schedule_cell {
  margin-top: 0.25rem;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  display: grid !important;
  grid-template-columns: repeat(7, 1fr);
  justify-items: center;
  gap: 0.25rem;
  padding: 0.5rem;
  background-color: #fafafa;
}
.schedule_cell h6 {
  font-size: 0.8rem;
}
.schedule_cell .clinic_badges {
  display: flex;
  gap: 0.25rem;
}
