/* Libre Franklin Fonts

Libre Franklin Regular

  font-family: "libre-franklin", sans-serif;
  font-weight: 400;
  font-style: normal;

Libre Franklin Italic

  font-family: "libre-franklin", sans-serif;
  font-weight: 400;
  font-style: italic;

Libre Franklin Medium

  font-family: "libre-franklin", sans-serif;
  font-weight: 500;
  font-style: normal;

Libre Franklin Medium Italic

  font-family: "libre-franklin", sans-serif;
  font-weight: 500;
  font-style: italic;

Libre Franklin Bold

  font-family: "libre-franklin", sans-serif;
  font-weight: 700;
  font-style: normal;

Libre Franklin Bold Italic

  font-family: "libre-franklin", sans-serif;
  font-weight: 700;
  font-style: italic; 

*/

@import "childrens.css";
@import url("https://use.typekit.net/eff7oes.css"); /*Libre Franklin Adobe Font Import*/

.page {
  line-height: 1.85;
}

.page .markdown *:not(.bar *) {
  text-align: left;
  font-family: Georgia, serif;
}

.page .markdown p {
  font-size: 1rem;
  margin-bottom: 1.3rem;
}

.page .markdown p:last-child {
  margin-bottom: 0;
}

.page .markdown h1,
.page .markdown h2,
.page .markdown h3,
.page .markdown h4,
.page .markdown h5,
.page .markdown h6:not(.bar *) {
  font-family: "libre-franklin", Arial, sans-serif;
  margin: 0.25rem 0 0.5rem;
  font-weight: inherit;
  line-height: 1.42;
  color: var(--childrens-purple);
}

.page .markdown h1:first-child,
.page .markdown h2:first-child,
.page .markdown h3:first-child,
.page .markdown h4:first-child {
  margin-top: 0;
}

.page .markdown h1 {
  margin-top: 0;
  font-size: 2rem;
  font-weight: bold;
}

.page .markdown h2 {
  font-size: 1.8rem;
  font-weight: medium;
}

.page .markdown h3 {
  font-size: 1.5rem;
  font-weight: normal;
}

.page .markdown h4 {
  font-size: 1.414rem;
}

.page .markdown h5 {
  font-size: 1.121rem;
  font-weight: bold;
}

/* style non-bar elements in the markdown */
.page .markdown h6:not(.bar *) {
  font-size: 0.88rem;
  font-weight: bold;
}

.page .markdown small {
  font-size: 0.707em;
}

/* https://github.com/mrmrs/fluidity */

.page .markdown img,
.page .markdown canvas,
.page .markdown iframe,
.page .markdown video,
.page .markdown svg,
.page .markdown select,
.page .markdown textarea {
  max-width: 100%;
}

.page .markdown {
  font-size: 18px;
  max-width: 100%;
}

.page .markdown {
  color: currentColor;
  font-family: Georgia, serif;
  margin: 0;
  max-width: 100%;
}

/* === A bit of a gross hack so we can have bleeding divs/blockquotes. */

.page .markdown p,
.page .markdown:not(.bar) *,
.page .markdown *::not(div):not(img):not(body):not(html):not(li):not(blockquote):not(p) {
  margin: 1rem auto 1rem;
  max-width: 36rem;
  padding: 0.25rem;
}

.page .markdown .bar div div {
  padding: 0.25rem 0;
}

.page .markdown div {
  /* width: 100%; */
}

.page .markdown div img {
  width: 100%;
}

.page .markdown hr {
  border-top: 1px dotted;
}

.page .markdown blockquote {
  border-left: 4px solid var(--childrens-purple);
  padding-left: 1rem;
  margin: 0.5rem 0;
  font-style: italic;
  max-width: 48rem;
}

.page .markdown blockquote:has(*.caution) {
  border-left: 4px solid var(--childrens-yellow);
}

.page .markdown blockquote:has(*.urgent) {
  border-left: 4px solid var(--childrens-red);
}

.page .markdown ul {
  margin-bottom: 1rem;
}

.page .markdown li {
  margin-left: 2rem;
  line-height: 1.3;
  margin-bottom: 0.3rem;
}

.page .markdown ul.contains-task-list {
  list-style-type: none;
}

.page .markdown li.task-list-item input[type="checkbox"] {
  width: unset;
}

.page .markdown li.task-list-item input[type="checkbox"][disabled] {
  accent-color: blue;
}

/* Table Styles */
.page .markdown table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.page .markdown table thead tr {
  background-color: var(--childrens-purple);
  color: #ffffff;
  text-align: left;
}

.page .markdown table th,
.page .markdown table td {
  padding: 12px 15px;
}

.page .markdown table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.page .markdown table tbody tr:nth-of-type(even) {
  /* to get this to work, need to create logic
  that changes the background color depending on theme
  otherwise, text changes to white and background
  remains light */
  /* background-color: #96c7f1; */
}

.page .markdown table tbody tr:last-of-type {
  border-bottom: 2px solid var(--childrens-purple);
}

/* Counteract the specificity of the gross *:not() chain. */

.page .markdown h1:not(:first-child) {
  padding: 1.25rem 0 0.25rem 0 !important;
}

/*  === End gross hack */

.page .markdown p {
  color: currentColor;
  height: auto;
  line-height: 1.45;
}

.page .markdown li:has(ul) p,
.page .markdown p:has(+ ul) {
  margin-bottom: 0.3rem;
}

.page .markdown pre,
.page .markdown code {
  font-family: Menlo, Monaco, "Courier New", monospace;
}

.page .markdown pre {
  background-color: #fafafa;
  font-size: 0.8rem;
  overflow-x: scroll;
  padding: 1.125em;
}

.page .markdown a,
.page .markdown a:visited {
  color: var(--childrens-medium-blue);
}

.page .markdown a:hover,
.page .markdown a:focus,
.page .markdown a:active {
  color: #acaeb0;
}
