.updated::after {
  padding: 0.2rem 0.4rem;
  margin-left: 0.25rem;
  font-size: 0.65rem;
  border-radius: 5px;
  color: white;
  vertical-align: middle;
}

.updated::after {
  content: "updated";
  background: #009cde;
}

.tree-selector {
  overflow: hidden;
}
