.page ul.children {
  margin-left: 3rem;
  border-left: 5px dotted var(--childrens-field-dark-heading-color);
  padding-top: 0.5rem;
}

.page ul.children.parent {
  padding-top: 0;
}

.page .children li {
  position: relative;
  padding: 0.5rem 1rem;
  transition: 0.5s;
  margin: 0 0 0 1rem;
  cursor: pointer;
  border-radius: 2rem;
  display: flex;
  align-items: center;
}

.page .children li::before {
  /* this is the circle on the dotted line */
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  left: -28px;
  top: 12px;
  background-color: #f4f4f4;
  border: 4px solid var(--childrens-field-dark-heading-color);
  border-radius: 50%;
  transition: 0.1s;
}

.page .children li.add-child::before {
  content: "+";
  line-height: 0.48;
  font-weight: bold;
  font-size: 20px;
  color: var(--childrens-field-dark-heading-color);
}
.page .children li:hover::before {
  background-color: var(--childrens-light-blue);
  border-color: var(--childrens-medium-blue);
  color: var(--childrens-medium-blue);
}
.page .children li::after {
  /* this is the horizontal line connecting to the ::before element above */
  position: absolute;
  content: "";
  width: 15px;
  height: 0px;
  left: -4px;
  top: 20px;
  border-top: 5px dotted var(--childrens-field-dark-heading-color);
  transition: 0.3s;
}

.page .children li:hover::after {
  border-color: var(--childrens-medium-blue);
}
.page .children li:hover {
  color: white;
  background: var(--childrens-medium-blue);
}

.page .container {
  position: relative;
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(300px, 1fr));
  grid-template-rows: minmax(100px, auto);
  grid-auto-flow: dense;
  grid-gap: 10px;
  background: #222;
  padding: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 45px 65px rgba(0, 0, 0, 0.5), 0 35px 22px rgba(0, 0, 0, 0.16);
  border-radius: 1rem;
}

.page .container .box {
  background: #333;
  display: grid;
  font-size: 20px;
  color: #fff;
  transition: 0.5s;
  padding: 20px;
}

.page .container .box.hide {
  transform: scaleY(0);
  height: 0;
  transition: 0.5s;
}

.page .container .box:not(:first-child):hover {
  background: #e91e63;
  background: var(--childrens-medium-blue);
}

.page .container .box:nth-child(1),
.page .container .box:nth-child(2) {
  grid-column: span 2;
  grid-row: span 1;
}

.page .container .box:nth-child(3) {
  grid-column: span 1;
  grid-row: span 2;
}

.page .container .box .content {
  display: flex;
  flex-wrap: nowrap;
  gap: 1rem;
}
.page .container .box .content:has(.title) {
  display: flex;
  align-items: center;
}

.page .container .box .content p.dim {
  color: var(--childrens-field-dark-heading-color);
  transition: 0.5s;
}

.page .container .box:hover p.dim {
  color: unset;
}

.page .container .box .content .icons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.page .container .box .content p:not(.title *) {
  font-family: georgiapro, sans-serif;
  white-space: pre-wrap;
  line-height: 1.4;
}

.page .container .box .content li.title {
  padding: 0.15rem 1rem;
  transition: 0.5s;
  margin: 0;
  width: 100%;
  cursor: pointer;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  font-size: smaller;
  color: var(--childrens-field-dark-heading-color);
}
.page .container .box .content li.title:last-child {
  color: inherit;
  font-size: inherit;
}
.page .container .box .content li.title:hover {
  transition: 0.5s;
  background: var(--childrens-medium-blue);
  color: inherit;
}

@media only screen and (max-width: 1199px) {
  .page .container {
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
    grid-template-rows: minmax(auto, auto);
  }

  .page .container .box {
    grid-column: unset !important;
    grid-row: unset !important;
  }
}
